
export const withComponent = (
  Component: any,
  Config: { defaultValue: any, clearable?: boolean, placeholder: string }
) => {
  const isDefault = Config.defaultValue !== undefined;
  // 设置默认值
  if (Component.props.modelValue === undefined && isDefault) {
    Component.props["onUpdate:modelValue"]?.(Config.defaultValue);
  }
  return Component ? (
    <Component
      {...Component.props}
      clearable={isDefault ? false : (Config.clearable) ?? true}
      {...Config}
      {...{ style: { width: "100%" } }}
    />
  ) : null;
};
